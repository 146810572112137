@use '@angular/material' as mat;
@import 'shared';
// Typography

// set angular material font
$custom-typography: mat.define-typography-config(
  $font-family: $font-family,
);
@include mat.all-component-typographies($custom-typography);

body {
  font-family: $font-family;
}

h1,
h2,
h3,
h4 {
  font-weight: 700;
  line-height: 125%;
}

h1 {
  font-size: toRem(60);
  @include media-breakpoint-down(lg) {
    font-size: toRem(45);
  }
}

h2 {
  font-size: toRem(48);
  @include media-breakpoint-down(lg) {
    font-size: toRem(36);
  }
}

h3 {
  font-size: toRem(30);
  @include media-breakpoint-down(lg) {
    font-size: toRem(23);
  }
}

h4 {
  font-size: toRem(20);
  @include media-breakpoint-down(lg) {
    font-size: toRem(15);
  }
}

.highlighted-text {
  font-weight: 700;
  font-size: toRem(36);
  line-height: 125%;
  @include media-breakpoint-down(lg) {
    font-size: toRem(27);
  }
}

.lead-text {
  font-weight: 600;
  font-size: toRem(30);
  line-height: 150%;
  @include media-breakpoint-down(lg) {
    font-size: toRem(23);
  }
}

p {
  font-weight: normal;
  font-size: toRem(16);
  line-height: 150%;
}

.title-text-content,
.text-content {
  ul {
    margin: 25px 0;

    li {
      padding-left: 25px;
      position: relative;
      margin-bottom: 15px;

      &:before {
        content: ' ';
        width: 7px;
        height: 7px;
        display: block;
        position: absolute;
        top: 5px;
        left: 0;
        background: color(primary, red);
      }
    }
  }
}

.lead-text,
.content-text {
  &.a,
  a {
    color: color(primary, red);
    text-transform: uppercase;
    font-weight: bold;
  }
}
