@import 'shared';

.filter-tag {
  margin: 5px;
  border-radius: 22px;
  font-size: toRem(12);
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  height: 28px;
  color: color(white);
  padding: 0 16px;

  @include media-breakpoint-down(md) {
    display: inline-block;
    height: auto;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  &.red {
    background: color(primary, red);
  }

  &.blue-light {
    background: color(primary, blue-light);
  }

  &.purple {
    background: color(primary, purple);
  }

  &.green {
    background: color(primary, green);
  }

  &.orange {
    background-color: color(secondary, orange-c);
  }

  &.blue {
    background: color(primary, blue);
  }

  &.clear {
    color: color(primary, red);

    .icon {
      width: 12px;
      height: 12px;
      margin-right: 12px;
      font-size: 0;
      @include media-breakpoint-down(md) {
        position: relative;
        top: 1px;
      }
    }
  }

  .filter-off {
    width: 12px;
    height: 12px;
    display: inline-block;
    margin-right: 12px;
    font-size: 0;
    @include icon('icons/x.svg');

    @include media-breakpoint-down(md) {
      position: relative;
      top: -4px;
    }
  }
}

.filter-clear {
  margin-left: auto;
}
