@import 'shared';

.card.card--info,
.card.card--trip {
  padding: 5px;
  display: block;
  cursor: pointer;

  @include media-breakpoint-down(md) {
    padding: 3px;
  }

  .card__inner {
    display: flex;
    align-items: flex-end;
    height: 444px;
    position: relative;
    background-color: #000;
    overflow: hidden;

    @include media-breakpoint-down(lg) {
      height: calc(50vw - 20px);
    }

    @include media-breakpoint-up(md) {
      &:hover {
        .card__content {
          &::before {
            top: 0;
          }
        }

        .card__text {
          height: 100%;
          opacity: 1;
        }

        app-spice-of-europe-logo {
          opacity: 0;
        }
      }
    }

    .card__link {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 11;
    }

    .card__bg {
      background-color: #000;
      display: block;
      background-size: cover;
      background-position: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    .card__content {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;
      height: 100%;
      transition: 0.5s ease;
      z-index: 2;

      &::before {
        position: absolute;
        top: 100%;
        right: 0;
        width: 100%;
        height: 100%;
        transition: 0.35s ease;
        z-index: -1;
        content: '';
        background: linear-gradient(0deg, rgba(15, 22, 45, 0.8) 0%, rgba(15, 22, 45, 0.7) 80%, rgba(15, 22, 45, 0) 100%);

        @include media-breakpoint-down(sm) {
          top: calc(100% - 95px);
        }
      }

      &::after {
        @include media-breakpoint-down(sm) {
          position: absolute;
          bottom: 10px;
          right: 10px;
          width: 24px;
          height: 24px;
          background: url('../../assets/images/icons/arrow-right-white-lite.webp') center / 100% no-repeat;
          content: '';
        }
      }
    }

    .card__locale {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      background: rgba(256, 256, 256, 0.8);

      @include media-breakpoint-up(lg) {
        width: 56px;
        height: 56px;
      }

      .icon {
        width: 21.5px;
        height: 21.5px;

        @include media-breakpoint-up(lg) {
          width: 30px;
          height: 30px;
        }
      }
    }

    .card__header {
      padding: 15px;
    }

    .card__title {
      letter-spacing: 0.01em;
      color: color(white);
      font-style: normal;
      font-weight: 600;
      font-size: toRem(30);
      line-height: 150%;

      @include media-breakpoint-down(sm) {
        font-size: toRem(20);
        line-height: 120%;
      }
    }

    .card__subtitle {
      display: inline-block;
      min-height: 18px;
      padding: 3px 10px 3px 0;
      font-style: normal;
      font-weight: normal;
      font-size: toRem(12);
      line-height: 12px;
      letter-spacing: 1px;
      text-transform: uppercase;
      border-radius: 0 10px 0 0;
      background: transparent;
      color: color(white);

      @include media-breakpoint-down(md) {
        font-size: toRem(10);
        line-height: 20px;
        position: relative;
        top: 11px;
      }

      @include media-breakpoint-down(xs) {
        display: none;
      }
    }

    .card__text {
      display: flex;
      align-items: center;
      height: 0;
      padding: 0 15px;
      transition: 0.5s ease;
      opacity: 0;
      font-style: normal;
      font-weight: normal;
      font-size: toRem(16);
      line-height: 150%;
      letter-spacing: 0.01em;
      color: color(white);

      @include media-breakpoint-down(md) {
        font-size: toRem(13);
      }

      @include media-breakpoint-down(xs) {
        display: none;
      }
    }
  }

  app-destination-icon,
  app-spice-of-europe-logo {
    position: absolute;
    top: 15px;
    left: 15px;
    width: 120px;
    transition: 0.35s ease;
    z-index: 3;
  }

  app-destination-icon {
    width: 20px;

    @include media-breakpoint-up(lg) {
      width: 50px;
    }

    img {
      padding: 0;
    }
  }

  app-spice-of-europe-logo {
    @include media-breakpoint-down(md) {
      width: 60px;
    }
  }
}

.card.card--info {
  .card__inner {
    .card__header {
      padding: 40px 15px 15px;

      @include media-breakpoint-up(lg) {
        padding-top: 56px;
      }

      @include media-breakpoint-down(sm) {
        padding: 40px 35px 15px 15px;
      }
    }
  }
}
