@import 'shared';

.ginop-input {
  > * {
    font-family: inherit;
  }

  .mat-form-field.mat-focused .mat-form-field-label {
    color: color(primary, blue);
  }
}
