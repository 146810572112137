*:focus-visible {
  outline: 8px solid #ffa200 !important;
}

*:focus:not(:focus-visible) {
  outline: none !important;
}

input:focus:hover {
  outline: 0 !important;
}
