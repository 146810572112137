@import 'shared';

html[lang='he'] {
  text-align: right;
}

app-root {
  display: block;
  max-width: 100%;
  overflow: hidden;
}

section {
  > .wrapper {
    width: $global-wrapper-width;
    margin: 0 auto;
    max-width: calc(100% - 30px);
  }
}

@include media-breakpoint-up(lg) {
  .page-wrapper {
    margin-top: -68px;
  }
}
