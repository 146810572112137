@use 'sass:math';

// Images path
$images-path: '/assets/images/';

// Wrapper width
$global-wrapper-width: 1180px;

// Font Family
$font-family: 'Montserrat', sans-serif;

// Colors
$black: #2D2B2C;
// Retrieve color from $colors map ie. `color(base, primary)`
@function color($color-name, $color-variant: null) {
  // color variant is optional
  @if ($color-variant !=null) {
    // map inception
    @return map-get(map-get($colors, $color-name), $color-variant);
  } @else {
    @return map-get($colors, $color-name);
  }
}

// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 600px,
  md: 800px,
  lg: 1024px,
  xl: 1280px,
);

// Color variable map example

$colors: (
  white: #fff,
  grey: #cbcbcb,
  black: #000,
  primary: (
    blue: #003375,
    red: #ef4e69,
    green: #38ba9b,
    green2: #39bb9d,
    purple: #7c51a1,
    blue-light: #70a3d7,
  ),
  secondary: (
    red-a: #f06564,
    red-b: #f27d83,
    red-c: #f04e69,
    ginop-red: #ef4e69,
    orange-a: #f79e6d,
    orange-b: #fcc480,
    orange-c: #f79462,
    orange-d: #f2994a,
    green-a: #b9d988,
    green-b: #93cb78,
    green-c: #82c894,
    green-d: #82ccb9,
    green-e: #76ccd8,
    blue-a: #45c0be,
    blue-b: #9cdbf8,
    blue-c: #4dbeed,
    blue-d: #009fda,
    purple-c: #724896,
    purple-a: #999ccd,
    purple-b: #afb4db,
    conf-blue-a: #1e3480,
    conf-blue-b: #023f87,
    conf-blue-c: #17479d,
    conf-blue-d: #659ad3,
    ginop-blue: #032f6e,
    conf-grey: #e2dddb,
    conf-dark: #2d2b2c,
    ginop-grey: #5b5b5b,
    ginop-grey-light: #f2f2f2,
    grey-a: #ded8d6,
    grey-b: #efeff0,
    grey-c: #f6f6f6,
    grey-d: #c4c4c4,
    grey-e: #979797,
    grey-f: #e0e0e0,
  ),
);

$blurbg: rgba(255, 255, 255, 0.1);
$blurbg_safe: rgba(170, 170, 170, 0.6);

// Nem kötelező használni a color functiont!
// Használat: color: color(white) vagy color(primary, base)

$mobileNavHeight: 44px;

$hero-height: 38vw;

$browser-context: 16;

// px to rem
// font-size: toRem(32) or font-size: toRem(32px);
@function toRem($pixels, $context: $browser-context) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return math.div($pixels, $context) * 1rem;
}
